var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row" }, [
    _c(
      "form",
      {
        staticClass: "w-100 mx-1",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.validate.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-column mb-4" },
          [
            _c("label", { attrs: { for: "cloud-type-input" } }, [
              _vm._v(" Cloud type "),
            ]),
            _c(
              "el-select",
              {
                attrs: {
                  id: "cloud-type-input",
                  filterable: "",
                  clearable: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.changeKeys(_vm.form.service)
                  },
                },
                model: {
                  value: _vm.form.service,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "service", $$v)
                  },
                  expression: "form.service",
                },
              },
              _vm._l(_vm.cloudStorages, function (value, name) {
                return _c("el-option", {
                  key: name,
                  attrs: { label: value, value: name },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._l(_vm.requiredItems, function (value, key) {
          return _c("base-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations[key],
                expression: "modelValidations[key]",
              },
            ],
            key: key,
            attrs: {
              name: key,
              error: _vm.getError(key),
              label: value,
              required: "",
            },
            model: {
              value: _vm.form[key],
              callback: function ($$v) {
                _vm.$set(_vm.form, key, $$v)
              },
              expression: "form[key]",
            },
          })
        }),
        _c(
          "base-input",
          { attrs: { label: "Notes", "input-id": "storage-notes-input" } },
          [
            _c("el-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.notes,
                  expression: "modelValidations.notes",
                },
              ],
              key: "notes",
              staticClass: "w-100",
              attrs: {
                id: "Notes--storage-notes-input",
                name: "notes",
                type: "textarea",
                rows: "2",
                autocomplete: "off",
              },
              model: {
                value: _vm.form.notes,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "notes", $$v)
                },
                expression: "form.notes",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "el-dialog__footer d-flex align-items-center" },
          [
            _c("div", { staticClass: "mr-auto" }, [
              _c("small", [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("info-icon", {
                      staticClass: "mr-1",
                      attrs: { title: "" },
                    }),
                    _vm._m(0),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "el-button",
              {
                staticClass: "ml-auto",
                attrs: { type: "default" },
                on: { click: _vm.onCancel },
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "el-button",
              { attrs: { "native-type": "submit", type: "primary" } },
              [_vm._v(" Save ")]
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-0" }, [
      _vm._v(" Don't see a platform supported? Please "),
      _c(
        "a",
        {
          attrs: {
            href: "https://helpcenter.getreviewbox.com/kb-tickets/new",
            target: "_blank",
          },
        },
        [_vm._v("contact us")]
      ),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
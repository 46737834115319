var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card rounded-0 storage-card" }, [
    _c(
      "div",
      {
        staticClass: "card-header d-flex align-items-center pb-3 bg-gray-light",
      },
      [
        _c("h4", { staticClass: "card-title mb-0" }, [
          _vm._v(" " + _vm._s(_vm.name) + " "),
        ]),
        _c(
          "div",
          { staticClass: "header-actions d-flex align-items-center ml-3" },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: `Update ${_vm.name} settings`,
                  "open-delay": 500,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "info", size: "small", plain: "" },
                    on: { click: _vm.onPressEdit },
                  },
                  [_vm._v(" Update ")]
                ),
              ],
              1
            ),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: `Disconnect ${_vm.name} from your account`,
                  "open-delay": 500,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", size: "small", plain: "" },
                    on: { click: _vm.onPressDelete },
                  },
                  [_vm._v(" Disconnect ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c("p", [
          _c("strong", [_vm._v("Service")]),
          _vm._v(": " + _vm._s(_vm.properDict[_vm.cred.service]) + " "),
        ]),
        _vm.cred.service !== "salesforce"
          ? _c("p", [
              _c("strong", [_vm._v("Bucket Name")]),
              _vm._v(": " + _vm._s(_vm.cred.bucket_name) + " "),
            ])
          : _vm._e(),
        _vm._l(_vm.hideShowList, function (item) {
          return _c(
            "div",
            { key: item.key, staticClass: "d-flex align-items-center mb-3" },
            [
              _c("p", { staticClass: "mb-0" }, [
                _c("strong", [_vm._v(_vm._s(item.title))]),
                _vm._v(": "),
                _vm.cred[item.key]
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.visible
                              ? _vm.cred[item.key]
                              : _vm.hiddenPassword
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.cred[item.key]
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: item.visible
                          ? "Hide " + item.title
                          : "Show " + item.title,
                        "open-delay": 500,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-2",
                          attrs: { size: "mini", circle: "" },
                          on: {
                            click: function ($event) {
                              item.visible = !item.visible
                            },
                          },
                        },
                        [item.visible ? _c("eye-off-icon") : _c("eye-icon")],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        _c("p", { staticClass: "storage-notes" }, [
          _c("strong", [_vm._v("Notes")]),
          _vm._v(": "),
          _vm.cred.notes
            ? _c(
                "span",
                [
                  _vm._v(" " + _vm._s(_vm.truncateNotes) + " "),
                  _vm.showCollapseTextButton
                    ? _c(
                        "el-button",
                        {
                          staticClass: "text-info",
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: () =>
                              (_vm.isCollapseText = !_vm.isCollapseText),
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isCollapseText
                                  ? "Collapse text"
                                  : "Read more"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
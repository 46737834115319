var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12 col-xl-8" },
        [
          _vm.isLoading ? [_vm._m(0), _vm._m(1)] : _vm._e(),
          !_vm.isLoading
            ? [
                _c("div", { staticClass: "card mb-3" }, [
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("p", [
                        _vm._v(
                          " Send exports directly to your organization's cloud storage. "
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleModal(false)
                            },
                          },
                        },
                        [_vm._v(" Link Storage ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "card mb-3" }, [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(
                          " Sync with an external CRM or case management system. "
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.linkCRM("salesforce")
                            },
                          },
                        },
                        [_vm._v(" Link Salesforce ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.linkCRM("zendesk")
                            },
                          },
                        },
                        [_vm._v(" Link Zendesk ")]
                      ),
                      !_vm.hasCredentials
                        ? _c("el-alert", {
                            staticClass: "mt-3",
                            attrs: {
                              type: "info",
                              title:
                                "There are no saved credentials yet. Please add new credentials.",
                              closable: false,
                              "show-icon": "",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.hasCredentials
                  ? _c("div", { staticClass: "card" }, [
                      _vm._m(4),
                      _c(
                        "div",
                        { staticClass: "row no-gutters" },
                        _vm._l(
                          _vm.initialStorageSettings,
                          function (cred, index) {
                            return _c(
                              "div",
                              {
                                key: `${index}-${_vm.cardKey}`,
                                staticClass: "col-12",
                              },
                              [
                                _c("storage-card", {
                                  staticClass: "border-bottom",
                                  attrs: { cred: cred, name: cred.name },
                                  on: {
                                    "emit:edit": function ($event) {
                                      return _vm.editStorageSettings(index)
                                    },
                                    "emit:delete": function ($event) {
                                      return _vm.deleteStorageSettings(index)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.editCred && _vm.editCred.name
                ? `Edit Cloud Credentials for ${_vm.editCred.name}`
                : "Save Cloud Credentials",
            top: "65px",
            visible: _vm.showModal,
            "destroy-on-close": "",
            width: "75%",
            "custom-class": "dialog-secondary storage-dialog",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showModal = $event
            },
          },
        },
        [
          _c("storage-form", {
            key: _vm.formKey,
            attrs: { cred: _vm.editCred, names: _vm.credNames },
            on: {
              "emit:submit": _vm.postStorageSettings,
              "emit:cancel": function ($event) {
                return _vm.toggleModal(true)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card mb-3" }, [
      _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
        _c("h4", { staticClass: "skeleton skeleton-title card-title mb-0" }),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "skeleton skeleton-text" }),
        _c("p", { staticClass: "skeleton skeleton-text" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
        _c("h4", { staticClass: "skeleton skeleton-title card-title mb-0" }),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "skeleton skeleton-text" }),
        _c("p", { staticClass: "skeleton skeleton-text" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
      _c("h4", { staticClass: "card-title mb-0" }, [
        _vm._v(" Add Cloud Storage "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
      _c("h4", { staticClass: "card-title mb-0" }, [
        _vm._v(" Add External Service "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
      _c("h4", { staticClass: "card-title mb-0" }, [
        _vm._v(" Connected Services "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
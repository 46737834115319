import { mapMutations } from 'vuex';
import { ReviewsHttp } from '@/services/api';

/**
 * Mixins for updating review/question metadata.
 * e.g.) sentiment, topic, status, comment
 *
 * These mixins are strictly used for Reviews and Questions.
 * These pages share a lot of the same methods so just keeping
 * code simple and less copy-pasta
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  data() {
    return {
      showDropdownColumnModal: {
        show: false,
        columnValues: [],
        field: null,
      },
    };
  },
  methods: {
    ...mapMutations('grids', [
      'addDropdownColumnValues',
      'removeDropdownColumnValues',
    ]),
    /**
     * Method to handle adding a value to column dropdown.
     *
     * @param {String} field Name of column field
     */
    onAddDropdownColumnValue(field) {
      this.showDropdownColumnModal = {
        show: true,
        columnValues: this.columnCellEditors(this.service)[field]
          .cellEditorParams.values.filter((value) => value !== 'Not Assigned'),
        field,
      };
    },
    onCancelDropdownColumns() {
      this.showDropdownColumnModal = {
        show: false,
        columnValues: [],
        field: null,
      };
    },
    /**
     * Method to handle adding, updating, and deleting
     * values for column dropdowns.
     *
     * @param {Object} payload Object of {valuesToAdd, valuesToDelete}
     *
     * @todo Refactor method to be more efficient.
     * Right now we have to accomodate for different parameter names and doing
     * a post and a patch for column data. Would be nice to have this be simplified.
     */
    onConfirmSaveDropdownColumns(payload) {
      const { valuesToAdd, valuesToDelete } = payload;
      const { field } = this.showDropdownColumnModal;
      const valuesToDeleteKeys = Object.keys(valuesToDelete);
      let nameOfMethod = null;
      const params = {};

      // Catch updates for topic column
      if (field === 'topic') {
        nameOfMethod = 'patchCustomTopics';
        params.custom_topics = JSON.stringify(valuesToAdd);
        params.topic_updates = JSON.stringify(valuesToDelete);
      }

      // Catch updates for customCol column
      if (field === 'customCol') {
        nameOfMethod = 'postCustomColFields';
        params.custom_fields = JSON.stringify(valuesToAdd);
        params.field_updates = JSON.stringify(valuesToDelete);
      }

      // Make a post/patch request to update column values
      if (nameOfMethod) {
        ReviewsHttp[nameOfMethod](params)
          .then(() => {
            // Notify the user that this was successfully saved
            this.$notify({
              type: 'success',
              title: `${field === 'topic' ? 'Topic' : 'Custom'} Values Updated`,
              message: `${field === 'topic' ? 'Topic' : 'Custom'} values were successfully updated.`,
              position: 'bottom-right',
            });

            /**
             * Add new values to dropdown
             *
             * @todo We have to add the values to both reviews and questions
             * since they are shared among services. Maybe in the future we can
             * refactor this to have their own values?
             */
            if (valuesToAdd && valuesToAdd.length) {
              this.addDropdownColumnValues({
                service: this.service,
                field,
                valuesToAdd
              });

              this.addDropdownColumnValues({
                service: this.service === 'reviews' ? 'questions' : 'reviews',
                field,
                valuesToAdd
              });
            }

            /**
             * Remove values from dropdown
             *
             * @todo We have to remove the values from both reviews and questions
             * since they are shared among services. Maybe in the future we can
             * refactor this to have their own values?
             */
            if (valuesToDeleteKeys && valuesToDeleteKeys.length) {
              this.removeDropdownColumnValues({
                service: this.service,
                field,
                valuesToDelete: valuesToDeleteKeys,
              });

              this.removeDropdownColumnValues({
                service: this.service === 'reviews' ? 'questions' : 'reviews',
                field,
                valuesToDelete: valuesToDeleteKeys,
              });
            }

            /**
             * Refresh the grid
             *
             * @todo The values do not get updated if re-fetching.
             * Say we change the dropdown value from "taco" -> "burrito"
             * Any cell value with "taco" will not change upon refresh.
             * We have to change date range and go back to date range.
             * This might have to do with caching in the back-end?
             * It sees the same date range and just returns the old cached rows.
             * Not sure if this is correct or not, but needs to be fixed before deployment.
             */
            if (valuesToDeleteKeys.length) {
              // Go back in and update current grid row data
              const itemsToUpdate = [];

              this.$refs[`${this.service}Grid`].gridApi.forEachNode((rowNode) => {
                const rowData = rowNode.data;

                if (valuesToDeleteKeys.indexOf(rowData[field]) >= 0) {
                  rowData[field] = valuesToDelete[rowData[field]];
                  itemsToUpdate.push(rowData);
                }
              });

              this.$refs[`${this.service}Grid`].gridApi.applyTransaction({ update: itemsToUpdate });
            }

            // Update column definition values
            if (field === 'customCol' || field === 'topic') {
              this.$refs[`${this.service}Grid`].gridApi.columnController.columnApi.getColumn(field).colDef.cellEditorParams.values = [...valuesToAdd];
              this.gridApi.refreshCells({ force: true });
            }
          })
          .catch((error) => this.handleError(error))
          .finally(() => {
            this.onCancelDropdownColumns();
          });
      } else {
        this.onCancelDropdownColumns();
      }
    },
  },
};

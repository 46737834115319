var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "archived-listings-container" }, [
    _c("section", { staticClass: "archived-listings" }, [
      _c(
        "div",
        {
          staticClass: "archived-toolbar mb-3 d-flex",
          class: {
            "align-items-end":
              _vm.selectedListings && _vm.selectedListingsLength,
            "align-items-center":
              !_vm.selectedListings || !_vm.selectedListingsLength,
          },
        },
        [
          _c("div", { staticClass: "d-none d-lg-flex flex-wrap" }, [
            _c(
              "div",
              { staticClass: "mr-1" },
              [
                _c("label", { staticClass: "d-block" }, [_vm._v(" Service ")]),
                _c(
                  "el-select",
                  {
                    attrs: { size: "mini", disabled: _vm.isLoading },
                    on: { change: _vm.onServiceChanged },
                    model: {
                      value: _vm.selectedService,
                      callback: function ($$v) {
                        _vm.selectedService = $$v
                      },
                      expression: "selectedService",
                    },
                  },
                  _vm._l(_vm.serviceOptions, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { value: item, label: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mr-1" },
              [
                _c(
                  "label",
                  { staticClass: "d-block" },
                  [
                    _vm._v(" Sort by "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          "open-delay": 500,
                          content:
                            "Sort by deletion date, from the most recent to oldest (newest) or oldest to most recent (oldest) order.",
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "sup",
                          [
                            _c("info-icon", {
                              staticClass:
                                "rb-icon-xs text-muted cursor-pointer",
                              attrs: { title: "" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-select",
                  {
                    attrs: { size: "mini", disabled: _vm.isLoading },
                    model: {
                      value: _vm.selectedSort,
                      callback: function ($$v) {
                        _vm.selectedSort = $$v
                      },
                      expression: "selectedSort",
                    },
                  },
                  _vm._l(_vm.sortOptions, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { value: item.value, label: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "d-block d-lg-none" },
            [
              _c(
                "el-popover",
                {
                  attrs: { placement: "bottom", trigger: "manual" },
                  on: { show: _vm.onPopoverShow },
                  model: {
                    value: _vm.showFilters,
                    callback: function ($$v) {
                      _vm.showFilters = $$v
                    },
                    expression: "showFilters",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "mb-2" },
                        [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v(" Service "),
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: _vm.isLoading, size: "mini" },
                              on: { change: _vm.onServiceChanged },
                              model: {
                                value: _vm.selectedService,
                                callback: function ($$v) {
                                  _vm.selectedService = $$v
                                },
                                expression: "selectedService",
                              },
                            },
                            _vm._l(_vm.serviceOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { value: item, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-2" },
                        [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v(" Sort by "),
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: _vm.isLoading, size: "mini" },
                              model: {
                                value: _vm.selectedSort,
                                callback: function ($$v) {
                                  _vm.selectedSort = $$v
                                },
                                expression: "selectedSort",
                              },
                            },
                            _vm._l(_vm.sortOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "float-right mt-2",
                          attrs: { type: "danger", plain: "", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onCloseFilters(false)
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        size: "small",
                        icon: "el-icon-s-operation",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onCloseFilters(!_vm.showFilters)
                        },
                      },
                      slot: "reference",
                    },
                    [_vm._v(" Filters ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectedListings && _vm.selectedListingsLength
            ? _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        "open-delay": 500,
                        content: `Unarchiving listings will restore them to ${_vm.selectedServiceLabel}`,
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "info",
                                plain: "",
                                size: "small",
                                icon: "el-icon-refresh-left",
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading,
                              },
                              on: { click: _vm.onUnarchiveListings },
                            },
                            [
                              _vm._v(
                                " Unarchive " +
                                  _vm._s(_vm.selectedListingsLength) +
                                  " " +
                                  _vm._s(
                                    _vm.selectedListingsLength > 1
                                      ? "listings"
                                      : "listing"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c("archive-listings-help-button", {
                attrs: { title: "How does this table work?" },
              }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              ref: "archivedTable",
              staticClass: "rounded shadow-sm",
              attrs: {
                data: _vm.pagedTableData,
                "empty-text": _vm.emptyTableText,
                "default-sort": { prop: "date", order: "descending" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          { attrs: { data: props.row.listings } },
                          [
                            _c("el-table-column", {
                              attrs: { property: "listing", label: "ASIN" },
                            }),
                            _c("el-table-column", {
                              attrs: { property: "source", label: "Source" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Date", property: "time" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDate(scope.row.time * 1000)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "No. Listings" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.listings.length) + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.total > _vm.pageSize
            ? _c("el-pagination", {
                staticClass: "d-flex justify-content-end my-3",
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.total,
                },
                on: { "current-change": _vm.setPage },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
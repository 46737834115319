import { mapGetters, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import { ReviewsHttp, ResponseHttp } from '@/services/api';
import { FormValidations } from '@/mixins';

export default {
  /**
   * Import the global mixin FormValidations to use
   * the method: createValidationObject to initialize
   * input validation objects for Vee-Validate.
   */
  mixins: [FormValidations],
  data() {
    return {
      isResponseDataLoading: false,
      isConfirmSubmit: false,
      defaultResponseMessage: null,
      form: {
        responseMessage: null,
        responseIntegration: null,
      },
      formValidations: {
        responseMessage: {
          required: true,
        },
      },
      formErrorMessage: null,
      selectedResponseTemplates: null,
      hasResponseChanged: false,
      customSelectedFilters: {},
      selectedResponseIntegration: null,
      responseIntegrationOptions: [],
      reviewData: {},
      isDraftLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getResponseTemplatesByType: 'account/getResponseTemplatesByType',
      userProfile: 'account/getProfile',
    }),
    hasCustomSelectedFilters() {
      return Object.values(this.customSelectedFilters).some((customSelectedFilter) => customSelectedFilter.length > 0);
    },
    responseTemplates() {
      const templateType = this.isReview ? 'review' : 'question';
      const templates = this.getResponseTemplatesByType(templateType);

      return templates;
    },
    filteredResponseTemplates() {
      const isFiltered = Object.values(this.customSelectedFilters).some((customSelectedFilter) => customSelectedFilter.length > 0);

      if (!isFiltered) {
        return this.responseTemplates;
      }

      return this.responseTemplates.filter((template) => {
        const { customFields } = template;

        if (!customFields) {
          return false;
        }

        const customFieldValues = customFields.map((field) => field.value);
        const filterValues = Object.values(this.customSelectedFilters).flat();

        // return true if all filtered values are in the custom fields
        return filterValues.every((filterValue) => customFieldValues.includes(filterValue));
      });
    },
    /**
     * Returns an aggregated list of the response templates
     */
    customFilters() {
      return this.aggregate(this.responseTemplates.map((r) => r.customFields));
    },
    /**
     * Whether an account has custom filters for their response templates.
     */
    hasCustomFilters() {
      return this.customFilters && Object.keys(this.customFilters).length !== 0;
    },
  },
  methods: {
    ...mapActions('account', ['getResponseTemplates']),
    aggregate(lists) {
      const aggregated = {};

      for (let i = 0; i < lists.length; i += 1) {
        const fields = lists[i];
        if (fields !== undefined) {
          for (let j = 0; j < fields.length; j += 1) {
            const field = fields[j];
            if (field.value !== '') {
              if (aggregated[field.key] === undefined) {
                aggregated[field.key] = [];
              }
              if (!aggregated[field.key].includes(field.value)) {
                aggregated[field.key].push(field.value);
              }
            }
          }
        }
      }
      return aggregated;
    },
    /**
     * Method to handle response input to toggle on
     * response draft info pop-over.
     *
     * Uses a lodash's debounce so as not to spam this method.
     */
    onResponseInput: debounce(function onResponseChange() {
      this.hasResponseChanged = true;
    }, 500),
    /**
     * Method to submit response. If message is valid,
     * trigger a confirmation step before submitting response.
     */
    onSubmitResponse() {
      // Check if response is not null
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.isConfirmSubmit = true;
        }
      });
    },
    /**
     * Method to actually submit response. Will make a POST
     * request and submit response for review/question.
     */
    confirmSubmitResponse() {
      const params = {
        response: this.form.responseMessage,
        response_integration: JSON.stringify(this.selectedResponseIntegration),
      };
      let metadata = {};

      // If a review response, add correct review metadata
      if (this.isReview) {
        metadata = JSON.stringify({
          source: this.reviewData.source || this.data.source,
          listing: this.reviewData.listing,
          retailer_source: this.reviewData.retailer_source,
          is_review: this.isReview,
          ts: this.reviewData.written_ts || this.data.written_ts,
          stars: this.reviewData.stars,
          text: this.reviewData.text,
          id: this.reviewData.id,
        });
        // Else it's a question, add correct question metadata
      } else {
        metadata = JSON.stringify({
          source: this.reviewData.source || this.data.source,
          listing: this.reviewData.listing,
          retailer_source: this.reviewData.retailer_source,
          is_review: this.isReview,
          ts: this.reviewData.written_ts || this.data.written_ts,
          text: this.reviewData.question,
          id: this.reviewData.id,
        });
      }

      // Object.assign all params for POST request
      // This will turn params to { response, review }
      // review is arbitrary property for review and question
      // so depending on this.isReview, your are setting up
      // params for a review or question response
      // NOTE: Don't have to post to response tracking
      // postReviewResponse does it in the api endpoint
      Object.assign(params, { review: metadata });

      // Post the response
      this.isLoading = true;

      ReviewsHttp.postReviewResponse(params)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Reply Submitted',
            message: 'Your reply was succesfully submitted. It may take between 36 – 48 hours before a reply posts to a retailer\'s product page.',
            position: 'bottom-right',
            duration: 5500
          });
          this.isResponseInputFocused = false;
          // Reset error bag when closing
          this.$validator.reset();

          // Return response message to default
          this.defaultResponseMessage = null;
          this.formErrorMessage = null;
          this.selectedResponseTemplates = null;
          this.form.responseMessage = null;
          this.hasResponseChanged = false;
          this.isResponseDataLoading = false;
          this.isResponseInputFocused = false;
          this.isDraftLoading = false;
          this.prependAuthorToMessage();
        })
        .catch((error) => {
          this.formErrorMessage = error.message;
        })
        .finally(() => {
          this.isLoading = false;
          this.isConfirmSubmit = false;
        });
    },
    /**
     * Method to reset modal values back to default.
     */
    resetValues() {
      // Reset error bag when closing
      this.$validator.reset();

      // Return response message to default
      this.defaultResponseMessage = null;
      this.formErrorMessage = null;
      this.selectedResponseTemplates = null;
      this.form.responseMessage = null;
      this.selectedResponseIntegration = null;
      this.hasResponseChanged = false;
      this.reviewData = {};
      this.isResponseDataLoading = false;
      this.isCollapseText = true;
      this.isResponseInputFocused = false;
      this.isDraftLoading = false;
    },
    /**
     * Prepend the response message with review/question author
     * name, for a friendly greet the user.
     */
    prependAuthorToMessage() {
      const { author } = this.reviewData;

      if (typeof author !== 'undefined' && author !== null) {
        this.form.responseMessage = `Hi ${author},\n`;
      } else {
        this.form.responseMessage = null;
      }
    },
    getTemplateResponses() {
      /**
       * Removes templates if last selected template is "None",
       * Removes "None" if a new template is selected.
       */
      let responses = this.selectedResponseTemplates;
      const numResponses = responses.length;

      if (responses[numResponses - 1] === '') {
        responses = [];
      } else {
        responses = responses.filter((response) => response !== '');
      }
      this.selectedResponseTemplates = responses;
      return responses;
    },
    onSelectResponseTemplate() {
      const responses = this.getTemplateResponses();

      // Replace tokens with actual values
      const responseTokens = [
        { reg: /<name>/gi, value: this.userProfile.name || '<name>' },
        { reg: /<user>/gi, value: this.userProfile.name || '<user>' },
        { reg: /<author>/gi, value: this.reviewData.author || '<author>' },
        { reg: /<product>/gi, value: this.reviewData.name || this.responseData.name || '<product>' },
      ];

      if (responses.length > 0) {
        let message = this.defaultResponseMessage || '';
        const reducedResponses = responses.reduce((acc, curr) => {
          let temp = curr;

          responseTokens.forEach((token) => {
            temp = temp.replace(token.reg, token.value);
          });

          return `${acc}${temp}\n\n`;
        }, '');

        message += reducedResponses;

        this.form.responseMessage = message;
      } else {
        this.prependAuthorToMessage();
      }

      // Mark change
      this.hasResponseChanged = true;
    },
    /**
     * Report this review/question as abusive.
     */
    submitSocialAction(action) {
      const params = {
        action,
        review: JSON.stringify(this.reviewData),
        is_review: this.isReview,
      };
      this.isLoading = true;
      const message = action === 'vote' ? 'marked as helpful' : 'marked as abuse';

      ReviewsHttp.postAction(params)
        .then(() => {
          // Lastly close modal if all is good
          this.$notify({
            type: 'success',
            title: 'Review Reported',
            message: `This review will be ${message} on Amazon.`,
            position: 'bottom-right',
          });
        })
        .catch((error) => {
          this.formErrorMessage = error.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * Save the response draft before closing the modal
     */
    onSaveDraft() {
      const type = this.isReview ? 'reviews' : 'questions';
      const { author, id, source } = this.reviewData;
      const params = {
        response: this.form.responseMessage,
        id,
        source,
        type,
      };

      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          if (this.form.responseMessage !== '' && this.form.responseMessage !== `Hi ${author},\n`) {
            this.isDraftLoading = true;
            // Make the API call here to save the draft response
            ResponseHttp.saveResponseDraft(params)
              .then(() => {
                // Success message on draft saved
                this.$notify({
                  type: 'success',
                  title: 'Draft Saved',
                  message: 'Your response draft was succesfully saved.',
                  position: 'bottom-right',
                });
              })
              .catch((error) => {
                this.formErrorMessage = error.message;
              })
              .finally(() => {
                this.isDraftLoading = false;
              });
          }
        }
      });
    },
    getResponseDraft(type, id, source) {
      const params = { type, id, source };

      if (type && id && source) {
        // return object from this API call would either be a
        // object with "response" in it or an {}.
        ResponseHttp.getResponseDraft(params)
          .then((data) => {
            if (data.response) {
              this.form.responseMessage = data.response;
            } else {
              this.prependAuthorToMessage();
            }
            this.defaultResponseMessage = this.form.responseMessage;
          })
          .catch((error) => {
            this.formErrorMessage = error.message;
          });
      }
    },
  },
};

import cloneDeep from 'lodash/cloneDeep';
import { mapMutations } from 'vuex';
import { ReviewsHttp } from '@/services/api';
import isNil from 'lodash/isNil';

/**
 * Mixins for updating review/question metadata.
 * e.g.) sentiment, topic, status, comment
 *
 * These mixins are strictly used for Reviews and Questions.
 * These pages share a lot of the same methods so just keeping
 * code simple and less copy-pasta
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  data() {
    return {
      bulkSelectedItems: null,
      enableBulkEdit: false,
      isBulkLoading: false,
      maxBulkAllowed: 1000,
    };
  },
  methods: {
    ...mapMutations('grids', ['updateSingleGridRow', 'bulkUpdateGridRows']),
    /**
     * Method to handle a row selected.
     * This will display a side drawer with review data,
     * to toggle response tracking and edit review metadata,
     * or bulk updating if more than one row selected.
     *
     * @param {Array} row Array of ag-grid row object metadata
     */
    onRowSelected(data) {
      const hasParams = !!this.gridDataQueries?.review && !!this.gridDataQueries?.listing && !!this.gridDataQueries?.source;

      if (data.length > 1) {
        this.responseData = null;
        this.bulkSelectedItems = data;
        // Close side drawer after data has been set
        this.$store.commit('grids/setShowResponseDrawer', false);
      } else if (data.length === 1) {
        [this.responseData] = data;
        // Open side drawer after data has been set
        this.$store.commit('grids/setShowResponseDrawer', true);
        this.bulkSelectedItems = null;
      } else {
        this.responseData = null;
        this.bulkSelectedItems = null;
        // Close side drawer after data has been set
        this.$store.commit('grids/setShowResponseDrawer', false);
      }

      if (this.$refs?.reviewsGrid && hasParams) {
        const filters = {
          id: {
            filterType: 'text',
            type: 'equals',
            filter: this.gridDataQueries.review
          }
        };
        this.$refs.reviewsGrid.onApplyFilter(filters);
      }
    },
    /**
     * Method to update a review/question metadata for a single value, e.g. topic, sentiment, status, customCol
     *
     * @param {Object} row Object of row data being updated
     * @param {String} field Name of field being updated
     * @param {String|Array} oldValue Previous value of field value before changing
     * @param {String|Array} newValue Value of field to change to
     */
    onUpdateReviewOrQuestionMetadata(row, field, oldValue, newValue) {
      /**
       * Update to newest value
       * @todo Refactor so we don't have to catch for customCol -> custom_field
       */
      const values = {};
      if (field !== 'customCol' && field !== 'topic') {
        values[field] = newValue;
      } else if (field === 'topic') {
        values.topic = JSON.stringify(newValue);
      } else if (field === 'customCol') {
        values.custom_field = JSON.stringify(newValue);
      }

      // id is associated to a review or question, but API needs "review" since this is how the DB is set up
      const { id: review, source, listing } = row;
      const params = {
        items: JSON.stringify([{ review, source, listing }]),
        values: JSON.stringify(values),
      };

      ReviewsHttp.updateMetadata(params)
        .then(() => {
          const updatedRow = { ...row };
          /**
           * @todo We shouldn't have to catch for customCol,
           * but we do not label them the same. Let's fix
           * this in the future
           */
          if (field === 'customCol') {
            try {
              const parsedCustomField = JSON.parse(values.custom_field);
              updatedRow[field] = parsedCustomField.length ? parsedCustomField : '';
            } catch (error) {
              updatedRow[field] = values.custom_field;
            }
          } else if (field === 'topic') {
            try {
              const parsedTopic = JSON.parse(values[field]);
              updatedRow[field] = parsedTopic.length ? parsedTopic : '';
            } catch (error) {
              updatedRow[field] = values[field];
            }
          } else {
            updatedRow[field] = values[field];
          }

          // Call grid mutation to update row data
          this.updateSingleGridRow({
            service: this.service,
            row: updatedRow,
            agGridRowNumber: updatedRow.agGridRowNumber,
          });

          // Go back in and update current grid row data
          const itemsToUpdate = [];
          this.$refs[`${this.service}Grid`].gridApi.forEachNode((rowNode) => {
            const rowData = rowNode.data;

            if (rowData.agGridRowNumber === updatedRow.agGridRowNumber) {
              rowData[field] = updatedRow[field];
              itemsToUpdate.push(rowData);
            } else {
              itemsToUpdate.push(rowData);
            }
          });

          this.$refs[`${this.service}Grid`].gridApi.applyTransaction({ update: itemsToUpdate });
        })
        .catch((error) => {
          this.handleError(error, {}, 'Unable to update. Please try again.');

          // Update failed to return value back to what it was
          const itemsToUpdate = [];
          this.$refs[`${this.service}Grid`].gridApi.forEachNode((rowNode) => {
            const rowData = rowNode.data;

            if (rowData.agGridRowNumber === row.agGridRowNumber) {
              rowData[field] = oldValue;
              itemsToUpdate.push(rowData);
            } else {
              itemsToUpdate.push(rowData);
            }
          });

          this.$refs[`${this.service}Grid`].gridApi.applyTransaction({ update: itemsToUpdate });
        });
    },
    /**
     * Method to handle toggling on response tracking for a review/question.
     *
     * @param {Object} params Http param object for response tracking
     */
    onUpdateReviewResponseTracking(params) {
      ReviewsHttp.updateReviewResponseTracking(params)
        .catch((error) => {
          // Track response tracking errors with Rollbar
          this.$rollbar.error('Error updating response tracking', error);
        });
    },
    /**
     * Method to handle cell values changing for reviews and questions.
     * This will trigger HTTP request to update cell field data.
     *
     * @param {Object} value A string specifying which download and rows selected
     */
    onCellValueChanged(cell) {
      const { colDef: { field }, data, column, newValue } = cell;
      let { oldValue } = cell;

      /**
       * @todo We rename column values with no real reason.
       * Anyway to refactor this to have uniform column/cell names
       * from back-end to front-end?
       */

      if (column.colId === 'topic' || column.colId === 'customCol') {
        if (!Array.isArray(oldValue)) {
          if (!oldValue) {
            oldValue = [];
          } else if (oldValue[0] === '[') {
            oldValue = JSON.parse(oldValue);
          } else {
            oldValue = [oldValue];
          }
        }

        if (newValue === 'Not Assigned') {
          oldValue = [];
        }

        if (oldValue === 'Not Assigned' || (oldValue.length === 1 && oldValue[0] === 'Not Assigned')) {
          oldValue = [];
        }

        if (column.colId === 'topic') {
          // Check if newValue is an Array
          if (Array.isArray(newValue)) {
            data.topic = cloneDeep(newValue);
          } else {
            // The newValue is a string
            // Deselect a value if it already exists
            const index = oldValue.findIndex((v) => v === newValue);
            if (index > -1) {
              data.topic = oldValue.filter((v) => v !== newValue).sort();
            } else {
              data.topic = oldValue.concat(data.topic).sort();
            }
          }
        } else if (column.colId === 'customCol') {
          // Check if newValue is an Array
          if (Array.isArray(newValue)) {
            data.customCol = cloneDeep(newValue);
          } else {
            // The newValue is a string
            // Deselect a value if it already exists
            const index = oldValue.findIndex((v) => v === newValue);
            if (index > -1) {
              data.customCol = oldValue.filter((v) => v !== newValue).sort();
            } else {
              data.customCol = oldValue.concat(data.customCol).sort();
            }
          }
        }
      }

      this.onUpdateReviewOrQuestionMetadata(
        data, // pass row data
        field, // pass field that is being changed
        oldValue, // pass old value in case we need to revert
        newValue, // pass new value to update field value
      );
    },
    onCloseResponseDrawer() {
      this.$refs[this.gridRef].gridOptions.api.deselectAll();
    },
    /**
     * Select the next row node in the grid
     */
    onSelectNext() {
      const { api } = this.$refs[this.gridRef].gridOptions;

      // Make sure a node exists
      const node = api.getSelectedNodes();

      if (isNil(node) || isNil(node[0])) {
        return;
      }

      let rowIdx = node[0].childIndex;

      // Increment index value
      if (api.getDisplayedRowCount() > (rowIdx + 1)) {
        rowIdx += 1;
      } else {
        // We're on the last row, and we do not
        // need to re-run any code here.
        return;
      }

      /**
       * Need to account for filtered and sorted rows
       *
       * See: https://www.ag-grid.com/documentation/vue/grid-api/#reference-rowNodes
       */
      api.forEachNodeAfterFilterAndSort((n) => {
        if (rowIdx === n.rowIndex) {
          // Reset the drawer data
          this.$refs.responseDrawer.resetResponseData();

          // Toggle loader
          this.$refs.responseDrawer.isLoading = true;

          // Select row and deselect others
          n.setSelected(true, true);
        }
      });
    },
    /**
     * Select the previous row node in the grid
     */
    onSelectPrev() {
      const { api } = this.$refs[this.gridRef].gridOptions;

      // Make sure a node exists
      const node = api.getSelectedNodes();

      if (isNil(node) || isNil(node[0])) {
        return;
      }

      let rowIdx = node[0].childIndex;

      // Decrement the row index
      if ((rowIdx - 1) >= 0) {
        rowIdx -= 1;
      } else {
        // We're on the first row, and we do not
        // need to re-run any code here.
        return;
      }

      /**
       * Need to account for filtered and sorted rows
       *
       * See: https://www.ag-grid.com/documentation/vue/grid-api/#reference-rowNodes
       */
      api.forEachNodeAfterFilterAndSort((n) => {
        if (rowIdx === n.rowIndex) {
          // Reset the drawer data
          this.$refs.responseDrawer.resetResponseData();

          // Toggle loader
          this.$refs.responseDrawer.isLoading = true;

          n.setSelected(true, true);
        }
      });
    },
    /**
     * Method to cancel bulk updating. This will exit out of the bulk mode and deselect rows.
     */
    onCancelBulkUpdate() {
      this.enableBulkEdit = false;
      this.bulkSelectedItems = null;
      this.isBulkLoading = false;
      const { api } = this.$refs[this.gridRef].gridOptions;

      if (api) {
        api.deselectAll();
      }
    },
    /**
     * Method to handle bulk updates for reviews or questions.
     * @param {*} payload
     */
    onSubmitBulkActions(payload) {
      this.isBulkLoading = true;
      const label = this.isReview ? 'Reviews' : 'Questions';
      const values = { ...payload };
      /**
       * @todo go back and make customCol match to custom_field, so we don't have to alter this when calling api
       * Also, why the need to stringify these arrays? Could we not stringify the entire params?
       */
      if (Object.prototype.hasOwnProperty.call(values, 'customCol')) {
        values.custom_field = JSON.stringify(payload.customCol);
        delete values.customCol;
      }

      if (Object.prototype.hasOwnProperty.call(values, 'topic')) {
        values.topic = JSON.stringify(payload.topic);
      }

      const items = this.bulkSelectedItems.map((item) => ({
        // review is used to generically for review and question...that is how the db is setup
        review: item.id,
        listing: item.listing,
        source: item.source,
      }));

      const params = {
        items: JSON.stringify(items),
        values: JSON.stringify(values),
      };

      ReviewsHttp.updateMetadata(params)
        .then(() => {
          this.updateRowData(payload);
          this.onCancelBulkUpdate();
          this.$notify({
            type: 'success',
            title: `${label} Updated`,
            message: `The ${label.toLowerCase()} have been successfully updated.`,
            position: 'bottom-right',
          });
        })
        .catch((e) => {
          this.$notify({
            type: 'error',
            title: `${label} Update Failed`,
            message: `Unable to update ${label}. Please try again later.`,
            position: 'bottom-right',
          });

          // Track metadata errors with Rollbar
          this.$rollbar.error('Error updating reviews/questions metadata', e);
        })
        .finally(() => {
          this.isBulkLoading = false;
        });
    },
    /**
     * Method to update ag-grid rows data, as well as vuex grid row info.
     *
     * @param {*} rows
     * @param {*} newValues
     */
    updateRowData(newValues) {
      const fields = Object.keys(newValues);
      const itemsToUpdate = [];
      const rowNodes = this.$refs[`${this.service}Grid`].gridApi.getSelectedNodes();

      rowNodes.forEach((node) => {
        const rowData = node.data;

        fields.forEach((field) => {
          rowData[field] = newValues[field];
        });

        itemsToUpdate.push(rowData);
      });

      // Call grid mutation to update rows data
      this.bulkUpdateGridRows({ service: this.service, rows: itemsToUpdate });

      // Update ag-grid instance row data
      this.$refs[`${this.service}Grid`].gridApi.applyTransaction({ update: itemsToUpdate });
    },
    /**
     * Method to format tooltip for bulk actions. List a users renamed column headers from headerFieldNames
     * or, if nothing, use the default column field names
     */
    formatHeaderFieldNames(obj) {
      if (obj) {
        const values = Object.values(obj);

        // Formats an array ['one', 'two', 'three'] -> 'one, two, and three'
        return values.join(', ').replace(/, ([^,]*)$/, ', and $1');
      }

      return 'topic, sentiment, status, and custom fields';
    }
  },
};

/**
 * Mixins for parsing route queries/parameters for Reviews and Questions
 *
 * These mixins are strictly used for Reviews and Questions.
 * These pages share a lot of the same methods so just keeping
 * code simple and less copy-pasta
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  data() {
    return {
      gridDataQueries: {},
      productName: null,
    };
  },
  methods: {
    /**
     * Method to parse route queries that are needed to fetch
     * grid data for reviews. Or for other reviews-related features.
     */
    parseRouteQueries() {
      // Parse Queries For Grid Data
      if (Object.keys(this.$route.query).length > 0) {
        const gridDataQueries = {};

        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'listing')) {
          gridDataQueries.listing = this.$route.query.listing;
        }

        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'source')) {
          gridDataQueries.source = this.$route.query.source;
        }

        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'question')) {
          gridDataQueries.question = this.$route.query.question;
        }

        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'review')) {
          gridDataQueries.review = this.$route.query.review;
        }

        // Add product name to local state, to display above grid
        // This means we are fetching data for a specific listing
        // so let users know for UI purposes
        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'name')) {
          this.productName = this.$route.query.name;
        }

        // Get queries for bazaarvoice, this is used for response integration
        if (
          Object.prototype.hasOwnProperty.call(this.$route.query, 'bazaarvoice')
          && this.$route.query.bazaarvoice === 'true'
        ) {
          gridDataQueries.retailer_id = this.$route.query.listing;
          gridDataQueries.retailer_source = this.$route.query.source;
          gridDataQueries.source = 'bazaarvoice';
        }

        this.gridDataQueries = gridDataQueries;
      }
    },
  },
};
